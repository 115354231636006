<template >
    <div v-if="show_workspace" class="modal-overlay" @click="_closeWindow">
        <div class="modal-container workspaces-modal" @click.stop>
            <div class="modal-header">
                <div class="title-wrapper">
                    <span class="material-icons-outlined header-icon">grade</span> 
                    <h3 class="modal-title">FAVORITOS</h3>
                            </div>
                <button class="modal-close-btn" @click="_closeWindow">
                    <i class="material-icons">close</i>
                </button>
                        </div>

            <div class="modal-body">
                <div class="ticker-info">
                    <span class="ticker-label">Ticker:</span>
                    <span class="ticker-name">{{ stock_listener.ticker }}</span>
                            </div>

                <div v-if="!isDeleting" class="workspaces-list-section">
                    <p class="section-title">Seleccionar Espacio de Trabajo</p>
                    <div class="list-container">
                        <div class="list-item workspace-item" 
                             v-for="workspace in workspaces" 
                             :key="workspace.name"
                             @click="currentlyEditing.name != workspace.name && !isCreating && (workspace.isFavorite ? _removeTickerFromWorkspace(workspace) : _addTickerToWorkspace(workspace))"
                             :class="{'clickable-item': currentlyEditing.name != workspace.name && !isCreating}">
                            <div v-if="currentlyEditing.name != workspace.name" class="item-content-wrapper">
                                <div class="workspace-selector">
                                    <span class="material-icons-outlined radio-icon">{{ workspace.isFavorite ? 'radio_button_checked' : 'radio_button_unchecked' }}</span>
                                    <span class="material-icons-outlined star-icon" :style="{ color: workspace.color }">star</span>
                                    <span class="item-text workspace-name">{{workspace.name}}</span>
                                </div>
                                <div class="item-actions">
                                    <button @click.stop="_editWorkspacePrepare(workspace)" class="icon-button edit-icon">
                                        <i class="material-icons">edit</i>
                                    </button>
                                    <button @click.stop="_deleteWorkspacePrepare(workspace)" class="icon-button delete-icon">
                                        <i class="material-icons">delete</i>
                                    </button>
                                </div>
                                </div>                           
                            <div v-else class="edit-input-wrapper" @keyup.esc="_editWorkspaceCancel()" @keyup.enter="_editWorkspaceConfirm()">
                                <button class="icon-button color-trigger-btn" @click="_toggleColorGrid('edit', $event)">
                                     <span class="material-icons-outlined star-icon" :style="{ color: editWorkspaceColor }">star</span>
                                </button>
                                <input 
                                    type="text" 
                                    class="vanilla-input edit-workspace-input" 
                                    v-model="editWorkspaceNewName" 
                                    placeholder="Nuevo nombre" 
                                    :maxlength="32" 
                                    ref="editWorkspaceInput"
                                />
                                <button class="icon-button confirm-edit-btn" @click="_editWorkspaceConfirm()">
                                    <i class="material-icons">check</i>
                                </button>
                                <button class="icon-button cancel-edit-btn" @click="_editWorkspaceCancel()">
                                    <i class="material-icons">close</i>
                                </button>
                            </div>                            
                        </div>
                    </div>

                    <div v-if="!isCreating && !isEditing" class="add-workspace-section">
                         <button class="action-btn add-workspace-btn" @click="_createWorkspacePrepare()">
                             <i class="material-icons">add</i>
                             <span>Crear Nuevo Espacio</span>
                         </button>
                    </div>
                    <div v-else-if="isCreating" class="create-workspace-form" @keyup.enter="_createWorkspaceConfirm()">
                         <button class="icon-button color-trigger-btn" @click="_toggleColorGrid('create', $event)">
                              <span class="material-icons-outlined star-icon" :style="{ color: createWorkspaceColor }">star</span>
                         </button>
                        <input
                            type="text" 
                            class="vanilla-input create-workspace-input" 
                            v-model="createWorkspaceNewName" 
                            placeholder="Nombre del espacio" 
                            :maxlength="32" 
                            ref="createWorkspaceInput"
                         />
                        <button class="icon-button confirm-create-btn" @click="_createWorkspaceConfirm()">
                            <i class="material-icons">check</i>
                        </button>
                        <button class="icon-button cancel-create-btn" @click="_createWorkspaceCancel()">
                             <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>

                 <div v-else class="delete-confirm-view">
                     <p class="delete-question-text">¿Está seguro que desea eliminar este espacio de trabajo?</p>
                     <div class="workspace-to-delete-info">
                         <span class="material-icons-outlined star-icon" :style="{ color: currentlyDeleting.color }">star</span>
                         <span class="workspace-name-delete">{{currentlyDeleting.name}}</span>
                     </div>
                     <p class="delete-warning">
                         <i class="material-icons warning-icon">warning</i>
                         Esta acción eliminará el espacio y desvinculará todos los tickers asociados.
                     </p>
                </div>
            </div>

            <div class="modal-footer" v-if="isDeleting">
                <button class="action-btn secondary-btn" @click="_deleteWorkspaceCancel()">Cancelar</button>
                <button class="action-btn delete-btn" @click="_deleteWorkspaceConfirm()">Eliminar</button>
            </div>
        </div>
        
        <div v-if="showColorGrid" class="color-grid-popup" :style="colorGridStyle" ref="colorGridPopup">
             <div v-for="(color, index) in gridColors" 
                  :key="index" 
                  class="color-grid-item" 
                  :style="{ backgroundColor: color }"
                  @click="_selectColor(color)">
            </div>
        </div>
    </div>
</template>



<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import inputFavorites from './configs/favorites-workspace.json';
import inputEditFavorites from './configs/edit-favorites-workspace.json';
import { mapMutations, mapGetters } from "vuex";

export default {
    mixins: [APICaller],
    components: { 
        InputTextOutline
    },
    name: 'Workspaces',
    props: {
        show_workspace: { type: Boolean},
    },

    data: () => ({
        input_favorites : inputFavorites,
        input_edit_favorites : inputEditFavorites,
        workspaces: [],
        isClosingShieldOn: false,
        isCreating: false,
        isDeleting: false,
        isEditing: false,
        createWorkspaceNewName: "",
        editWorkspaceNewName: "",
        currentlyDeleting: {},
        currentlyEditing: {},
        createWorkspaceColor: "#00AEDD",
        editWorkspaceColor: "#000000",        
        initialized: false,
        stock_listener: null,
        
        showColorGrid: false,
        colorGridTarget: null,
        colorGridStyle: {},
        gridColors: [
            "#ff0000", "#ff6000", "#ffbf00", "#dfff00", "#80ff00", "#20ff00", "#00ff40", "#00ff9f", "#00ffff", "#009fff", "#0040ff", "#2000ff", "#8000ff", "#df00ff", "#ff00bf", "#ff0060"
        ]
    }),

    watch: {
        show_workspace(newValue) {
            if (newValue && !this.initialized) {
                this._initializeAndFetchData();
            }
            if (newValue && this.initialized) {
                this._updateFavoritesInfo();
            }
        },
        _g_SelectedTicker: {
            immediate: true,
            handler(newTicker) {
                this.stock_listener = newTicker;
                if (this.initialized && this.show_workspace) {
                    this._updateViewStockFavoriteWorkspaces();
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(["_g_UserFeatures", "_g_SelectedTicker"]),
    },

    created() {
    },

    mounted() {
        document.addEventListener('click', this._handleClickOutside, true);
        window.addEventListener('resize', this._handleResize);
    },

    beforeDestroy() {
        document.removeEventListener('click', this._handleClickOutside, true);
        window.removeEventListener('resize', this._handleResize);
    },

    methods: {
        ...mapMutations(["setWorkspaceCreated"]),
        _initializeAndFetchData() {
             this._initWorkspaces();
             this.stock_listener = this._g_SelectedTicker;
             let checkInit = setInterval(() => {
                 if (this.workspaces.length > 0) {
                     this._updateViewStockFavoriteWorkspaces();
                     this.initialized = true;
                     clearInterval(checkInit);
                 }
             }, 100); 
             setTimeout(() => clearInterval(checkInit), 5000);
        },

        _initWorkspaces: function() {
            let success = response => {
                this.workspaces = response.data.folders.map(folder => ({
                    name: folder[0],
                    color: folder[1],
                            isFavorite: false,
                }));
            };
            let url = '/api/v1/stock-finder/favorite-folder/list';
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('098', 'Error cargando lista de favoritos.');
            this._getAPICall(url, successHandler, failureHandler);
        },

        _updateFavoritesInfo: function() {
            if (!this.initialized) {
                console.warn("Attempted to update favorites info before initialization.");
                return;
            }
                    this._updateViewStockFavoriteWorkspaces();
        },

        _closeWindow: function() {
            this.$emit('_closeFavoriteWorkspacesWindow');
        },

        _updateViewStockFavoriteWorkspaces: function() {
            if (!this.stock_listener || !this.stock_listener.folder_name) {
                this.workspaces.forEach(ws => ws.isFavorite = false);
                return;
            }
            const favoriteFolders = this.stock_listener.folder_name || [];
            this.workspaces.forEach(workspace => {
                workspace.isFavorite = favoriteFolders.includes(workspace.name);
            });
        },

        _createWorkspacePrepare: function() {
            if(this._g_UserFeatures.permissions.extra_favorites <= this.workspaces.length) {
                 let toast = {type: 'warning', body: 'Con su plan actual no puede crear más espacios de trabajo. PULSE AQUÍ para mejorar su plan.', clickHandler: function(){ window.location.replace("/app/store"); }};
                 this.$vueOnToast.pop(toast);
                 return;
             }
                this._editWorkspaceCancel();
                this.createWorkspaceColor = this._generateRandomHexColor();
                this.isCreating = true;
            this.$nextTick(() => {
                this.$refs.createWorkspaceInput?.focus();
            });
        },
        _createWorkspaceConfirm: function() {
            const newName = this.createWorkspaceNewName.trim();
            if (newName === '') { 
                this.$vueOnToast.pop('error', "Error", "El nombre no puede estar vacío.");
                return; 
            }
            if (this.workspaces.some(ws => ws.name.toLowerCase() === newName.toLowerCase())) {
                this.$vueOnToast.pop('error', "Error", `El espacio de trabajo '${newName}' ya existe.`);
                return;
            }

                let params = new URLSearchParams();
            params.append('name', newName);
                params.append('color', this.createWorkspaceColor);
                let url = '/api/v1/stock-finder/favorite-folder/create';

            let success = () => {
                this.workspaces.push({ 
                    name: newName, 
                    color: this.createWorkspaceColor, 
                    isFavorite: false 
                });
                this.$vueOnToast.pop('success', "Espacio de trabajo " + newName, "creado con éxito");
                this.setWorkspaceCreated(true);
                this._createWorkspaceCancel();
                };

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('286', 'Error creando el espacio de trabajo.');
                this._postAPICall(url, params, successHandler, failureHandler);
        },
        _createWorkspaceCancel: function() {
            this.isCreating = false;
            this.createWorkspaceNewName = "";
            this.showColorGrid = false;
        },
        _deleteWorkspacePrepare: function(workspace) {
            this._editWorkspaceCancel();
            this._createWorkspaceCancel();
            this.isDeleting = true;
            this.currentlyDeleting = workspace;
        },
        _deleteWorkspaceConfirm: function() {
            if (!this.currentlyDeleting || !this.currentlyDeleting.name) return;

            let params = new URLSearchParams();
            params.append('name', this.currentlyDeleting.name);
            let url = '/api/v1/stock-finder/favorite-folder/delete';
            let deletedName = this.currentlyDeleting.name;

            let success = () => {
                this.$vueOnToast.pop('success', "Espacio de trabajo " + deletedName, "eliminado con éxito");
                this.workspaces = this.workspaces.filter(ws => ws.name !== deletedName);
                this.setWorkspaceCreated(true);
                this._deleteWorkspaceCancel();
            };
            let failure = () => {
                this._deleteWorkspaceCancel();
            };

            const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('290', 'Error eliminando el espacio de trabajo.', failure);
                this._postAPICall(url, params, successHandler, failureHandler);
        },
        _deleteWorkspaceCancel: function() {
            this.isDeleting = false;
            this.currentlyDeleting = {};
            this.showColorGrid = false;
        },
        _editWorkspacePrepare: function(workspace) {
            this._createWorkspaceCancel();
             this.currentlyEditing = { ...workspace };
             this.editWorkspaceNewName = workspace.name;
             this.editWorkspaceColor = workspace.color;  
            this.isEditing = true;
             this.$nextTick(() => {
                 this.$refs.editWorkspaceInput?.focus();
             });
        },
        _editWorkspaceConfirm: function() {
            const newName = this.editWorkspaceNewName.trim();
            const originalName = this.currentlyEditing.name;
            const newColor = this.editWorkspaceColor;
            const originalColor = this.currentlyEditing.color;

            if (newName === '') {
                this.$vueOnToast.pop('error', "Error", "El nombre no puede estar vacío.");
                return;
            }
            if (newName.toLowerCase() !== originalName.toLowerCase() && 
                this.workspaces.some(ws => ws.name.toLowerCase() === newName.toLowerCase())) {
                this.$vueOnToast.pop('error', "Error", `El espacio de trabajo '${newName}' ya existe.`);
                return;
            }
            if (newName === originalName && newColor === originalColor) {
                this._editWorkspaceCancel();
                return;
            }

            var params = new URLSearchParams();
            params.append('name', originalName);
            params.append('new_name', newName);
            params.append('new_color', newColor);
            let url = '/api/v1/stock-finder/favorite-folder/update';
            
            let success = () => {
                this.$vueOnToast.pop('success', "Espacio de trabajo", "actualizado con éxito");
                const index = this.workspaces.findIndex(ws => ws.name === originalName);
                if (index !== -1) {
                    this.workspaces[index].name = newName;
                    this.workspaces[index].color = newColor;
                     if (newName !== originalName && this.stock_listener?.folder_name?.includes(originalName)) {
                         this.stock_listener.folder_name = this.stock_listener.folder_name.map(fn => fn === originalName ? newName : fn);
                         this.workspaces[index].isFavorite = true;
                     } else {
                         this.workspaces[index].isFavorite = this.stock_listener?.folder_name?.includes(newName) ?? false;
                     }
                }
                this.setWorkspaceCreated(true);
                this._editWorkspaceCancel();
            };

             let failure = () => {
                 this._editWorkspaceCancel();
            };

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('290', 'Error guardando cambios.', failure);
            this._postAPICall(url, params, successHandler, failureHandler);
        },
        _editWorkspaceCancel: function() {
            this.isEditing = false;
            this.editWorkspaceNewName = "";
            this.currentlyEditing = {};
            this.showColorGrid = false;
        },
         _setParamsToManageWorkspaces: function (workspaceName) {
             if (!this.stock_listener || !this.stock_listener.ticker) {
                 console.error("Cannot manage workspace, no stock selected.");
                 return null;
             }
             let paramsApiRequest = {
                 ticker: this.stock_listener.ticker,
                 direccio: this.stock_listener.direccio,
                 folder_name: workspaceName
             };
            let params = new URLSearchParams();
            params.append('stock', JSON.stringify(paramsApiRequest));
            return params;
        },
        _addTickerToWorkspace: function(workspace) {
             var params = this._setParamsToManageWorkspaces(workspace.name);
             if (!params) return;
            this._setWorkspaceAsFavorite(workspace, params);
        },
        _removeTickerFromWorkspace: function(workspace) {
             var params = this._setParamsToManageWorkspaces(workspace.name);
              if (!params) return;
            this._unsetWorkspaceAsFavorite(workspace, params);
        },
        _setWorkspaceAsFavorite(workspace, params) {
            let success = () => {
                 this.$vueOnToast.pop('success', `Ticker añadido a ${workspace.name}!`);
                 workspace.isFavorite = true;
                 if (this.stock_listener && this.stock_listener.folder_name && !this.stock_listener.folder_name.includes(workspace.name)) {
                     this.stock_listener.folder_name.push(workspace.name);
                 }
                 
                 // Ensure the favorites property is set before emitting
                 if (this.stock_listener) {
                     this.stock_listener.favorites = true;
                 }
                 
                 // Emit event to update favorites in parent component
                 this.$emit('_favorite_added', this.stock_listener);
             };
            let url = '/api/v1/stock-finder/save/favorites';
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('288', 'Error agregando el ticker a favoritos.');
            this._postAPICall(url, params, successHandler, failureHandler);
        },
        _unsetWorkspaceAsFavorite(workspace, params) {
            let success = () => {
                 this.$vueOnToast.pop('success', `Ticker eliminado de ${workspace.name}!`);
                 workspace.isFavorite = false;
                 
                 if (this.stock_listener && this.stock_listener.folder_name) {
                     const index = this.stock_listener.folder_name.indexOf(workspace.name);
                     if (index > -1) {
                         this.stock_listener.folder_name.splice(index, 1);
                     }
                     
                     // Only set favorites to false if no folders contain this stock
                     if (this.stock_listener.folder_name.length === 0) {
                         this.stock_listener.favorites = false;
                     } else {
                         // Maintain favorites=true if still in at least one folder
                         this.stock_listener.favorites = true;
                     }
                 }
                 
                 // Emit event to update favorites in parent component
                 this.$emit('_favorite_removed', this.stock_listener);
             };
            let url = '/api/v1/stock-finder/delete/favorites';
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('289', 'Error eliminando el ticker de favoritos.');
            this._postAPICall(url, params, successHandler, failureHandler);
        },
        _generateRandomHexColor: function() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                 color += letters[Math.floor(Math.random() * 12) + 4]; 
            }
            return color;
        },
        _toggleColorGrid(context, event) {
            if (this.showColorGrid && this.colorGridTarget === context) {
                this.showColorGrid = false;
                return;
            }
            const triggerButton = event.target.closest('button');
            if (!triggerButton) return;

            const buttonRect = triggerButton.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const gridWidth = 130; 
            const gridHeight = 130;
            
            let posLeft = buttonRect.right + 8;
            let posTop = buttonRect.top;
            
            if (posLeft + gridWidth > viewportWidth) {
                posLeft = buttonRect.left - gridWidth - 8;
            }
            if (posLeft < 0) {
                posLeft = buttonRect.left;
                posTop = buttonRect.bottom + 8;
                if (posTop + gridHeight > viewportHeight) {
                    posTop = buttonRect.top - gridHeight - 8;
                }
            }
            
            posLeft = Math.max(8, Math.min(posLeft, viewportWidth - gridWidth - 8));
            posTop = Math.max(8, Math.min(posTop, viewportHeight - gridHeight - 8));
            
            this.colorGridStyle = {
                position: 'fixed',
                top: `${posTop}px`,
                left: `${posLeft}px`,
            };
            this.colorGridTarget = context;
            this.showColorGrid = true;
        },
        _selectColor(color) {
            if (this.colorGridTarget === 'create') {
                this.createWorkspaceColor = color;
            } else if (this.colorGridTarget === 'edit') {
                this.editWorkspaceColor = color;
            }
            this.showColorGrid = false;
        },
        _handleClickOutside(event) {
             if (this.showColorGrid && this.$refs.colorGridPopup && !this.$refs.colorGridPopup.contains(event.target)) {
                 const isTrigger = event.target.closest('.color-trigger-btn');
                 if (!isTrigger) {
                     this.showColorGrid = false;
                 }
             }
        },
        _handleResize() {
            if (this.showColorGrid) {
                this.showColorGrid = false;
            }
        },
  },
}
</script>


<style lang="scss" scoped>
.modal-overlay {
        position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.modal-container.workspaces-modal {
  background-color: var(--color-background, white);
  border-radius: 8px;
  width: 95%;
  max-width: 420px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
  color: var(--color-text-primary, #0F0F1A); 
}

.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  color: var(--primary, #00AEDD);
  font-size: 22px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary, #606577);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
  font-size: 20px;
}

.modal-body {
  padding: 15px 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.modal-body::-webkit-scrollbar {
    width: 8px;
}
.modal-body::-webkit-scrollbar-track {
    background: var(--color-surface, #F3F4F7);
    border-radius: 4px;
}
.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary, #B0B4C3);
    border-radius: 4px;
    border: 2px solid var(--color-surface, #F3F4F7);
}

.ticker-info {
    background-color: var(--color-surface, #F3F4F7);
    padding: 10px 15px;
    border-radius: 6px;
        margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticker-label {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
    }

    .ticker-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
}

.section-title {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-highlight, #00AEDD);
    text-transform: uppercase;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
}

.list-container {
    display: flex;
    flex-direction: column;
    gap: 5px; 
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 5px;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out;
}

.list-item.clickable-item {
    cursor: pointer;
}

.list-item.clickable-item:not(:has(.edit-input-wrapper)):hover {
    background-color: var(--color-surface, #F3F4F7);
}

.list-item.clickable-item:not(:has(.edit-input-wrapper)):hover .item-text {
     color: var(--primary, #00AEDD);
}

.item-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.workspace-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    overflow: hidden;
}

.radio-icon {
    font-size: 20px;
    color: var(--primary, #00AEDD);
}

.star-icon {
    font-size: 20px;
}

.item-text {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.workspace-name {
    color: var(--color-text-primary, #0F0F1A);
}

.item-actions {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-secondary, #606577);
    transition: color 0.2s ease, background-color 0.2s ease;
}

.icon-button:hover {
    background-color: var(--color-muted, #EAECEF); 
}

.icon-button i,
.icon-button span.material-icons-outlined {
    font-size: 18px;
}

.delete-icon:hover {
    color: var(--color-error, #B00020);
}
.edit-icon:hover {
    color: var(--primary, #00AEDD);
}
.confirm-edit-btn:hover, 
.confirm-create-btn:hover {
    color: var(--color-success, #57a869);
}
.cancel-edit-btn:hover, 
.cancel-create-btn:hover {
    color: var(--color-error, #B00020); 
}

.edit-input-wrapper,
.create-workspace-form {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px 0;
}

.color-swatch-edit,
.color-swatch-create {
    margin-right: 8px;
    flex-shrink: 0;
}

.color-trigger-btn {
    padding: 0;
    width: 28px; 
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.color-trigger-btn .star-icon {
     font-size: 20px; 
}

.vanilla-input {
    flex-grow: 1;
    margin-right: 8px;
    height: 34px;
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease;
    box-sizing: border-box;
}
.vanilla-input:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}
.vanilla-input::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
}

.add-workspace-section {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.add-workspace-btn {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 38px;
    background-color: var(--color-surface, #F3F4F7);
    color: var(--primary, #00AEDD);
    border: 1px dashed var(--color-outline, rgba(0,0,0,0.2));
    border-radius: 6px;
}

.add-workspace-btn:hover {
     background-color: var(--color-muted, #EAECEF); 
}

.add-workspace-btn i {
    font-size: 20px;
}

.delete-confirm-view {
    padding: 10px 0;
        text-align: center;
}

.delete-question-text {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 1.4;
}

.workspace-to-delete-info {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-surface, #F3F4F7);
    padding: 8px 15px;
    border-radius: 6px;
    margin-bottom: 15px;
    color: var(--color-text-primary, #0F0F1A);
}

.workspace-name-delete {
    font-weight: 600;
}

.delete-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--color-warning, #FF9B00);
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.3;
}

.warning-icon {
    font-size: 20px;
    flex-shrink: 0;
}

.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-shrink: 0;
}

.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}

.secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}
.secondary-btn:hover {
    background-color: var(--color-muted, #EAECEF); 
}

.delete-btn {
    background-color: var(--color-error, #B00020);
    color: white;
}
.delete-btn:hover {
    background-color: #9a001b;
}

.color-grid-popup {
    position: fixed;
    background-color: var(--color-background, white);
    border-radius: 8px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.25);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 24px);
    grid-template-rows: repeat(4, 24px);
    gap: 8px;
    z-index: 9999;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
}

.color-grid-item {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.1));
    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.color-grid-item:hover {
    transform: scale(1.15);
    border-color: var(--color-text-primary, #0F0F1A);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.color-grid-item:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary, #00AEDD);
    transform: scale(1.15);
    }
</style>