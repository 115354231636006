<template>
  <!-- Use standard modal structure -->
  <div v-if="show_waiting" class="modal-overlay" @click="_closeWindow">
    <div class="modal-container add-waiting-modal" @click.stop>
      <!-- Modal Header -->
      <div class="modal-header">
        <div class="title-wrapper">
            <span class="material-icons-outlined header-icon">playlist_add</span>
            <h3 class="modal-title">Añadir ticker a cartera</h3>
        </div>
        <button class="modal-close-btn" @click="_closeWindow">
            <i class="material-icons">close</i>
        </button>
                </div>

      <!-- Modal Body -->
      <div class="modal-body">
        <div class="ticker-info">
          <span class="ticker-label">Ticker:</span>
          <span class="ticker-name">{{ stock.ticker }}</span>
                </div>

        <!-- Portfolio Selection -->
        <div class="form-group cartera-select" v-if="user_portfolios.length > 0 && portfolio_selected.length > 0">
          <label class="input-label">Seleccionar Cartera</label>
          <select id="add-waiting-cartera-element" class="vanilla-select" v-model="portfolio">
            <option disabled value="">-- Seleccione --</option> 
            <option v-for="p in user_portfolios" :key="p.value" :value="p.value">
              {{ p.name }}
            </option>
          </select>
                </div>
        <div v-else class="info-message">Cargando carteras...</div>

        <!-- Instrument Type Selection -->
        <div class="form-group cartera-select">
          <label class="input-label">Tipo de Instrumento</label>
          <select id="cartera-element" class="vanilla-select" v-model="instrument">
            <option v-for="type in instrument_type_options" :key="type.value" :value="type.value">
               {{ type.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="modal-footer">
         <button class="action-btn secondary-btn" @click="_closeWindow">Cancelar</button>
         <button 
            class="action-btn primary-btn"
            @click="_sendAction" 
            :disabled="!portfolio || !instrument">
            Añadir a Cartera
         </button>
        </div>
    </div>
</div>
</template>

<script>
import "vue-swatches/dist/vue-swatches.css";
import Button from "@/components/helpers/Button";
import APICaller from "@/components/codeComponents/APICaller.js";
import inputFavorites from './configs/favorites-workspace.json';
import inputEditFavorites from './configs/edit-favorites-workspace.json';
import { mapGetters } from "vuex";
import SelectOutlineWallet from "@/components/helpers/SelectOutline_wallet.vue";
import SelectWallet from "@/components/stockchart/components/configs/select_waiting.json";
import SelectType from "@/components/stockchart/components/configs/select_type_instrument.json";

export default {
    mixins: [APICaller],
    components: { 
        SelectOutlineWallet,
        Button
    },
    name: 'WaitingValuesAdd',
    props: {
        stock: { type: Object, default: () => ({}) },
        show_waiting: { type: Boolean},
    },

    data: () => ({
        input_favorites : inputFavorites,
        input_edit_favorites : inputEditFavorites,
        workspaces: [],
        isClosingShieldOn: false,
        isCreating: false,
        isDeleting: false,
        isEditing: false,
        createWorkspaceNewName: "",
        editWorkspaceNewName: "",
        currentlyDeleting: {},
        currentlyEditing: {},
        createWorkspaceColor: "#000000",
        editWorkspaceColor: "#000000",

        user_portfolios:[],
        select_items: SelectWallet,
        portfolio_selected:[],
        helper_text_select:"",

        instrument_type_options: [ 
          { name: 'Stock', value: 'STOCK' },
          { name: 'CFD', value: 'CFD' } 
        ],
        select_type: SelectType,

        portfolio: '',
        instrument: 'STOCK',

        initialized: false,

    }),

    watch: {
        show_waiting(newValue) {
            if (newValue && !this.initialized) {
                this._initializeData();
            }
            if (newValue && this.initialized) {
                 this._updateSelectionsBasedOnStock();
            }
        },
        stock: {
            handler(newStock) {
                if (this.show_waiting && this.initialized) {
                   this._updateSelectionsBasedOnStock(newStock);
                }
            },
            deep: true
        }
    },

    computed: {
    },

    created() {
    },

    mounted() {
    },

    methods: {
        _initializeData: function() {
            if (!this.stock || Object.keys(this.stock).length === 0) return; 
            this._getPortfolios(); 
                this.initialized = true;
        },
        
        _updateSelectionsBasedOnStock: function(currentStock = this.stock) {
             if (this.portfolio_selected.length > 0) {
                 this.portfolio = this.portfolio_selected[0].name;
             }
             this.instrument = this.instrument || this.instrument_type_options[0].value;
        },
        
        _getPortfolios: function() {
            let success = (response) => {                
                this.user_portfolios = response.data.map(p => ({ name: p.name, value: p.idc }));
                this._defaultPortfolio();
            };
            let url = '/api/v1/portfolio/list';
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('092', 'Error cargando lista de carteras.');
            this._getAPICall(url, successHandler, failureHandler);
        },

        _defaultPortfolio: function(){
             if (this.user_portfolios.length > 0) {
                 let defaultP = this.user_portfolios[0]; 
                 this.portfolio_selected = [{ name: defaultP.value, value: defaultP.name }];
                 this.portfolio = defaultP.value;
             } else {
                 console.warn("Default portfolio called before portfolios loaded.");
             }
        },

        _sendAction: function(){
            if (!this.portfolio || !this.instrument) return;

            let params = new URLSearchParams();
            params.append('instrument_type', this.instrument);
            params.append('ticker', this.stock.ticker);
            if (this.stock.tipus_entrada) params.append('entry_type', this.stock.tipus_entrada);
            if (this.stock.direccio) params.append('direction', this.stock.direccio);

            let url = '/api/v1/portfolio/waitings/add/' + this.portfolio;

            let success = () => {
                this.$vueOnToast.pop('success', "Ticker " + this.stock.ticker, "Enviado a cartera!");
                this.$emit("waiting-added");
                this._closeWindow();
            };

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('279', 'Error agregando ticker a la cartera.');
            this._postAPICall(url, params, successHandler, failureHandler);
        },

        _closeWindow: function() {
             this.$emit('_closeWaitingValueWindow');
        },

        _generateRandomHexColor: function() {
            let randomColor = Math.floor(Math.random()*16777215).toString(16);
            return "#" + randomColor;
        },
  },
}
</script>

<style lang="scss" scoped>
/* Import shared modal styles if available, or define here */

/* Modal Overlay */
.modal-overlay {
        position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

/* Modal Container */
.modal-container.add-waiting-modal {
  background-color: var(--color-background, white);
  border-radius: 8px;
  width: 95%;
  max-width: 400px; /* Adjust width as needed */
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(15, 15, 26, 0.15);
  color: var(--color-text-primary, #0F0F1A); 
}

/* Modal Header */
.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-icon {
  color: var(--primary, #00AEDD);
  font-size: 22px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary, #606577);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
  font-size: 20px;
}

/* Modal Body */
.modal-body {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.ticker-info {
    background-color: var(--color-surface, #F3F4F7);
    padding: 10px 15px;
    border-radius: 6px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticker-label {
    color: var(--color-text-secondary, #606577);
    font-size: 14px;
}

.ticker-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-primary, #0F0F1A);
}

.form-group {
        margin-bottom: 20px;
    }

.input-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary, #0F0F1A);
}

/* Style the SelectOutlineWallet component */
.cartera-select ::v-deep .select-container {
    /* Add specific styles if needed, e.g., width */
    width: 100%;
}

.cartera-select ::v-deep .vs__dropdown-toggle {
    border-radius: 4px !important; 
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    height: 40px;
}

.cartera-select ::v-deep .vs__selected {
    color: var(--color-text-primary, #0F0F1A) !important;
    margin: 0; 
    padding: 0 0 0 8px;
    line-height: 38px; /* Vertically center text */
}

.cartera-select ::v-deep .vs__search {
     color: var(--color-text-primary, #0F0F1A) !important;
     padding: 0 0 0 8px !important;
}

.cartera-select ::v-deep .vs__search::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
}

.cartera-select ::v-deep .vs__dropdown-menu {
    background-color: var(--color-background, white);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    box-shadow: 0 3px 6px rgba(15, 15, 26, 0.1);
    color: var(--color-text-primary, #0F0F1A);
}

.cartera-select ::v-deep .vs__dropdown-option {
    padding: 8px 12px;
    font-size: 14px;
}

.cartera-select ::v-deep .vs__dropdown-option--highlight {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--primary, #00AEDD);
}

.cartera-select ::v-deep .vs__clear,
.cartera-select ::v-deep .vs__open-indicator {
    fill: var(--color-text-secondary, #606577) !important;
}

.info-message {
        text-align: center;
    color: var(--color-text-secondary, #606577);
    padding: 10px;
    font-style: italic;
}


/* Modal Footer */
.modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-shrink: 0;
}

/* Standard action buttons */
.action-btn {
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
}

.primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
}

.primary-btn:hover:not(:disabled) {
    background-color: #0095c5; /* Darker primary */
}

.primary-btn:disabled {
    background-color: var(--color-muted, #EAECEF); 
    color: var(--color-text-inactive, rgba(0,0,0,0.4));
    cursor: not-allowed;
}

.secondary-btn {
    background-color: var(--color-surface, #F3F4F7);
    color: var(--color-text-primary, #0F0F1A);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
}

.secondary-btn:hover {
    background-color: var(--color-muted, #EAECEF); 
}

/* Remove old styles */
/* #workspace-favorites-fullscreen.waitings-pc-full { ... } */
/* .addwaitings-workspace-container-style { ... } */
/* .user-workspaces { ... } */
/* .title-favorites { ... } */
/* .add-button-container { ... } */
/* .close { ... } */
/* #workspace-select-favorite-icon { ... } */
/* .remove-text-container { ... } */
/* .remove-star-container { ... } */
/* .remove-button-container input { ... } */
/* .cancel-button-container input { ... } */
/* .remove-star-containter { ... } */
/* .cartera-1 { ... } */
/* .button-class-pc { ... } */
/* .close-waiting-modal { ... } */

/* Style for vanilla select */
.vanilla-select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none; /* Remove default arrow */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23606577' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E"); /* Add custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px 16px;
}

.vanilla-select:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
    }
</style>